var menueAktiv = false;
const main = document.getElementById('main');
const menu = document.getElementById('menu');
const header = document.querySelector('.header');
const headerMenue = document.querySelector('.header__menue');
const hamburger = document.getElementById('header__toggle');

function menueToggle() {
    var menue = document.querySelector('.header__menue');
    
    if (menueAktiv) {
        menueAktiv = false;
        document.body.setAttribute('data-menue-aktiv', menueAktiv);
        main.style.transform = 'translateY(0)';
        menu.style.transform = 'translateY(0)';
        header.style.transform = 'translate(-50%, 0)';
        hamburger.style.transform = 'translateY(0)';
    } else {
        menueAktiv = true;
        menueVerschiebung = headerMenue.offsetHeight + 'px';
        document.body.setAttribute('data-menue-aktiv', menueAktiv);
        main.style.transform = `translateY(${menueVerschiebung})`;
        menu.style.transform = `translateY(${menueVerschiebung})`;
        header.style.transform = `translate(-50%, ${menueVerschiebung})`;
        hamburger.style.transform = `translateY(-${menueVerschiebung})`;
        
        var scrollposition = window.scrollY;

        document.addEventListener('scroll', function gescrollt() {
            if (Math.abs(scrollposition - window.scrollY) > 30) {
                menueToggle();
                this.removeEventListener('scroll', gescrollt);
            }
        });
    }
    document.body.setAttribute('data-menue-animiert', 'true');
    setTimeout(function() {
        document.body.setAttribute('data-menue-animiert', 'false');
    }, 1000);
}


// Hamburgermenü aktivieren
if (hamburger !== null) {
    hamburger.addEventListener('click', event => {
        menueToggle();
    });
}